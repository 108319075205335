import React, {RefObject} from "react";
import Label from "../../../../../components/Label/Label";
import Input from "../../../../../components/Input/Input";
import {ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Section from "../../../../../components/Drawer/Section/Section";
import LabelsAnnotationsSection, {LabelsAnnotationsSectionProps} from "../../../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import Description from "../../../../../components/Description/Description";
import {StorageV1VirtualCluster} from "../../../../../../gen/models/agentstorageV1VirtualCluster";
import Query from "../../../../../components/Query/Query";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import AutoComplete, {defaultAutoCompleteFilter} from "../../../../../components/AutoComplete/AutoComplete";
import {arr} from "../../../../../lib/helpers/renderhelper";
import {ManagementV1VirtualClusterTemplate} from "../../../../../../gen/models/managementV1VirtualClusterTemplate";
import constants from "../../../../../constants/constants";
import {randomString} from "../../../../../lib/helper";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
import {displayNameToID} from "../../../../../components/Drawer/Sections/Metadata/Metadata";

interface MetadataState {
    name: string | undefined;
    nameChanged: boolean;

    displayName: string | undefined;

    description: string | undefined;
    namespace: string | undefined;
}

interface MetadataProps extends SectionProps, LabelsAnnotationsSectionProps {
    cluster?: string;
    vCluster?: StorageV1VirtualCluster;

    noMargin?: boolean;
}

export default class VClusterMetadata extends React.PureComponent<MetadataProps, MetadataState> {
    labelsSectionRef?: RefObject<LabelsAnnotationsSection>;
    state: MetadataState = {
        name: this.props.vCluster ? this.props.vCluster?.metadata?.name : undefined,
        nameChanged: false,

        displayName: this.props.vCluster ? this.props.vCluster?.metadata?.annotations?.[constants.LoftDisplayNameAnnotation] : undefined,

        description: this.props.vCluster ? this.props.vCluster?.metadata?.annotations?.[constants.LoftDescriptionAnnotation] : undefined,
        namespace: this.props.vCluster ? this.props.vCluster?.metadata?.namespace : undefined,
    };

    constructor(props: MetadataProps) {
        super(props);

        this.labelsSectionRef = React.createRef<LabelsAnnotationsSection>();
    }

    create = async (vCluster: StorageV1VirtualCluster): Promise<ResultError> => {
        let name = this.state.name;
        if (!this.state.name) {
            name = "myvcluster-" + randomString(5);
        }

        // make sure metadata exists
        if (!vCluster.metadata) {
            vCluster.metadata = {};
        }
        if (!vCluster.metadata.annotations) {
            vCluster.metadata.annotations = {};
        }

        vCluster.metadata.name = name;
        if (this.state.displayName) {
            vCluster.metadata.annotations[constants.LoftDisplayNameAnnotation] = this.state.displayName;
        }
        if (this.state.description) {
            vCluster.metadata.annotations[constants.LoftDescriptionAnnotation] = this.state.description;
        }
        vCluster.metadata.namespace = this.state.namespace || "vcluster-"+vCluster.metadata.name+"-"+randomString(5);
        return this.labelsSectionRef!.current!.create(vCluster.metadata);
    };

    update = async (vCluster: StorageV1VirtualCluster): Promise<ResultError> => {
        if (this.state.displayName) {
            if (!vCluster.metadata?.annotations) {
                vCluster.metadata!.annotations = {};
            }
            vCluster.metadata!.annotations[constants.LoftDisplayNameAnnotation] = this.state.displayName;
        } else if (vCluster.metadata?.annotations) {
            delete vCluster.metadata.annotations[constants.LoftDisplayNameAnnotation]
        }
        if (this.state.description) {
            if (!vCluster.metadata?.annotations) {
                vCluster.metadata!.annotations = {};
            }
            vCluster.metadata!.annotations[constants.LoftDescriptionAnnotation] = this.state.description;
        } else if (vCluster.metadata?.annotations) {
            delete vCluster.metadata.annotations[constants.LoftDescriptionAnnotation]
        }

        return this.labelsSectionRef!.current!.update(vCluster.metadata!);
    };

    batch = (vClusters: StorageV1VirtualCluster[]): ResultError => {
        return this.labelsSectionRef!.current!.batch(vClusters.map(vCluster => vCluster.metadata!));
    };

    selectTemplate = (vClusterTemplate: ManagementV1VirtualClusterTemplate | undefined) => {
        let metadata = vClusterTemplate?.spec?.template?.metadata;
        if (vClusterTemplate?.metadata?.name) {
            if (!metadata) {
                metadata = {}
            }
            if (!metadata.annotations) {
                metadata.annotations = {}
            }
            metadata.annotations[constants.LoftVirtualClusterTemplate] = vClusterTemplate.metadata.name
        }
        
        this.labelsSectionRef!.current!.setMetadata(metadata);
    }

    renderNamespace() {
        if (!this.props.cluster) {
            return null;
        }

        if (this.props.mode === "update") {
            return <Input readOnly={true} value={this.state.namespace} />;
        }

        return <Query query={async () => await client.cluster(this.props.cluster!, Resources.ClusterV1Space).List()} refetch={[this.props.cluster]}>
            {
                result => {
                    return <AutoComplete placeholder={"vcluster-[name] (will be created if not exists)"}
                                         value={this.state.namespace}
                                         filterOption={defaultAutoCompleteFilter}
                                         showArrow={true}
                                         allowClear={true}
                                         onChange={(val) => this.setState({namespace: val})}
                                         options={arr(result.data?.items).map(space => ({label: space.metadata?.name!, value: space.metadata?.name!}))} />
                }
            }
        </Query>
    }

    renderVirtualClusterInfo() {
        return <div>
            <div className={"row"}>
                <div>
                    <Label>Display Name</Label>
                    <Input placeholder={"My Virtual Cluster"}
                           value={this.state.displayName}
                           onChange={(e) => {
                               if (this.props.mode === "create" && !this.state.nameChanged) {
                                   this.setState({displayName: e.target.value, name: displayNameToID(e.target.value)})
                               } else {
                                   this.setState({displayName: e.target.value})
                               }
                           }} />
                </div>
                <div>
                    <Label>Kubernetes Name (ID)</Label>
                    <Input readOnly={this.props.mode === "update"}
                           placeholder={"myvirtualcluster"}
                           value={this.state.name}
                           onChange={(e) => this.setState({name: e.target.value, nameChanged: true})} />
                </div>
            </div>
            <Label>Namespace</Label>
            {this.renderNamespace()}
            <Description>Choose a namespace to run this virtual cluster in.</Description>
            <SectionExpander name={"Description"}>
                <Label>Description</Label>
                <Input placeholder={`The Virtual Cluster is doing...`}
                       value={this.state.description}
                       onChange={e => this.setState({description: e.target.value})} />
                <Description>A human readable description what the virtual cluster is used for. Markdown can be used here.</Description>
            </SectionExpander>
        </div>
    }

    render() {
        return <Section title={`Metadata`} noMargin={this.props.noMargin}>
            {this.props.mode !== "batch" && this.renderVirtualClusterInfo()}
            <LabelsAnnotationsSection {...this.props} metadata={this.props.vCluster ? this.props.vCluster.metadata : undefined} filterAnnotations={[constants.LoftDisplayNameAnnotation, constants.LoftDescriptionAnnotation]} ref={this.labelsSectionRef} />
        </Section>
    }
}