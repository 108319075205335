import React, {useEffect} from "react";
import client from "../../../lib/client";
import {Resources} from "../../../lib/resources";
import DynamicTime from "../../../components/DynamicTime/DynamicTime";
import Table, {TableActions} from "../../../components/Table/Table";
import useQuery from "../../../lib/Query/Query";
import styles from "./Teams.module.scss";
import {ToggleColumn, ToggleColumnContent} from "../../../contexts/ToggleColumn/ToggleColumn";
import {DeleteOutlined, SettingOutlined} from "@ant-design/icons/lib";
import {deleteConfirm} from "../../../lib/Modal/Modal";
import ClientMessage from "../../../lib/Message/ClientMessage";
import {Button} from "antd";
import {DrawerDispatch, useItemDrawer} from "../../../contexts/drawer/DrawerContext";
import {arr, getDisplayName} from "../../../lib/helpers/renderhelper";
import {Tooltip} from "../../../components/Tooltip/Tooltip";
import {ManagementV1Team} from "../../../../gen/models/managementV1Team";
import TeamDrawer from "./TeamDrawer/TeamDrawer";
import {creationTimestampSorter} from "../../../lib/helpers/sorthelper";
import ShowYamlPopup from "../../../components/ShowYamlPopup/ShowYamlPopup";
import UserHeader from "../UserHeader/UserHeader";
import {renderClusterAccountStatus} from "../Users";
import FixedText from "../../../components/FixedText/FixedText";
import {Link, useHistory} from "react-router-dom";
import * as H from "history";
import Description from "../../../components/Description/Description";
import Query from "../../../components/Query/Query";

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch, history: H.History<H.LocationState>) {
    const editTeam = (team: ManagementV1Team) => {
        drawerDispatcher({
            title: "Edit Team: " + team.metadata?.name!,
            content: <TeamDrawer mode={"update"} team={team} refetch={refetch} />
        })
    };

    return [
        {
            title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name (ID)']}/>,
            render: (team: ManagementV1Team) => {
                const link = `/users/teams/${team.metadata?.name}`
                return <ToggleColumnContent id={"displayname"} columns={[
                    () => {
                        return <Link to={link}>
                            <FixedText className={styles["owner"]} text={getDisplayName(team.spec?.displayName, team.spec?.username, team.metadata?.name)} />
                        </Link>;
                    },
                    () => {
                        return <Link to={link}>
                            <FixedText className={styles["owner"]} text={team.metadata?.name} />
                        </Link>;
                    },
                ]}/>
            }
        },
        {
            title: "Status",
            render: (team: ManagementV1Team) => {
                if (team.metadata?.deletionTimestamp) {
                    return <span className="color-warning">Deleting</span>;
                }
                
                // check if the cluster account templates were successful
                const clusterAccountTemplateStatus = renderClusterAccountStatus(team.status);
                if (clusterAccountTemplateStatus) {
                    return clusterAccountTemplateStatus;
                }

                return "Active";
            }
        },
        {
            title: "Description",
            render: (team: ManagementV1Team) => {
                return <Description.Column>
                    {team.spec?.description || ""}
                </Description.Column>;
            }
        },
        {
            title: 'Members',
            render: (team: ManagementV1Team) => {
                const members = arr(team.spec?.users).length;
                return members + " user" + (members !== 1 ? "s" : "");
            }
        },
        {
            title: 'Group Members',
            render: (team: ManagementV1Team) => {
                const groups = arr(team.spec?.groups).length;
                return groups + " group" + (groups !== 1 ? "s" : "");
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: ManagementV1Team, b: ManagementV1Team) => creationTimestampSorter(a, b),
            render: (team: ManagementV1Team) => {
                return <DynamicTime timestamp={team.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "180px",
            render: (team: ManagementV1Team) => {
                return <TableActions className={styles["actions"]}>
                    <Tooltip title="edit">
                        <SettingOutlined className={styles["setting"]} onClick={() => editTeam(team)} />
                    </Tooltip>
                    <ShowYamlPopup className={styles["setting"]} object={team} resource={Resources.ManagementV1Team} name={team.metadata?.name!} refetch={refetch} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={styles["delete"]} onClick={() => {
                            deleteConfirm({
                                title: `Delete Team: ${team?.metadata?.name}`,
                                content: `Are you sure you want to delete the team ${team?.metadata?.name}? This action CANNOT be reverted!`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading();
                                    const result = await client.management(Resources.ManagementV1Team).Delete(team.metadata?.name!);
                                    message.Result(result);
                                    await refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
}

function filter(item: ManagementV1Team, value: string) {
    return !!(item.metadata?.name?.includes(value) || item.spec?.displayName?.includes(value) || item.spec?.description?.includes(value));
}

function Teams() {
    const history = useHistory();
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1Team).List());
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };
    useEffect(() => {
        const timeout = window.setTimeout(() => refetch(), 4000);
        return () => {
            window.clearTimeout(timeout);
        }
    }, [refetch]);

    return <div>
        <Table loading={!data && loading} columns={getTableColumns(refetch, drawerDispatcher, history)} dataSource={data ? arr(data.items).map(team => { return {...team, key: team.metadata!.name!}}) : undefined} error={error} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: <UserHeader>
                <Description.Table>
                    Teams are composed of multiple users and define a way to manage cluster access or other objects for multiple users at once. You can assign users automatically to teams by their groups, which can be synced from an authentication provider. Teams can also access Loft through their own access keys and own spaces or other objects. Click on a team to view the access keys and clusters the team can access.
                </Description.Table>
            </UserHeader>,
            right: <Query query={async () => await client.management(Resources.ManagementV1Team).CanI("create")}>
                {
                    result => {
                        if (result.loading || !result.data) {
                            return null;
                        }

                        return <Button type={"primary"} onClick={() => {
                            drawerDispatcher({
                                title: "Add Team",
                                content: <TeamDrawer mode={"create"} refetch={refetch} />
                            });
                        }}>Add Team</Button>
                    }
                }
            </Query>,
            selectedActions: <React.Fragment>
                <Tooltip title={"edit"}>
                    <SettingOutlined className={styles["setting-batch"]} onClick={() =>
                    {
                        const teams: ManagementV1Team[] = [];
                        for (let i = 0; i < selectedRowKeys.length; i++) {
                            const teamName = selectedRowKeys[i];
                            const team = data!.items!.find(team => team.metadata?.name === teamName);
                            if (!team) {
                                continue;
                            }

                            teams.push(team);
                        }

                        if (teams.length === 0) {
                            return;
                        }

                        if (teams.length === 1) {
                            const team = teams[0];
                            drawerDispatcher({
                                title: "Edit Team: " + team?.metadata?.name!,
                                content: <TeamDrawer mode={"update"} team={team} refetch={refetch} />
                            })
                        } else {
                            drawerDispatcher({
                                title: "Bulk Edit Selected Teams",
                                content: <TeamDrawer mode={"batch"} teams={teams} refetch={refetch} />
                            })
                        }

                        setSelectedRowKeys([]);
                    }} />
                </Tooltip>
                <Tooltip title={"delete"}>
                    <DeleteOutlined className={styles["delete-batch"]} onClick={() =>
                    {
                        deleteConfirm({
                            title: `Delete Teams`,
                            content: `Are you sure you want to delete the teams ${selectedRowKeys.join(", ")}? This action CANNOT be reverted! This will delete all teams accounts, accountquotas and spaces in all clusters as well.`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    const teamName = selectedRowKeys[i];
                                    const result = await client.management(Resources.ManagementV1Team).Delete(teamName as string);
                                    if (result.err) {
                                        message.Error(result);
                                        return;
                                    }
                                }

                                message?.DoneManagement();
                                await refetch();
                                setSelectedRowKeys([]);
                            }
                        });
                    }} />
                </Tooltip>
            </React.Fragment>
        }} />
    </div>
}

export default Teams;