import React, {useEffect} from "react";
import client from "../../lib/client";
import {Resources} from "../../lib/resources";
import DynamicTime from "../../components/DynamicTime/DynamicTime";
import Table, {TableActions} from "../../components/Table/Table";
import useQuery from "../../lib/Query/Query";
import styles from "./Users.module.scss";
import {ToggleColumn, ToggleColumnContent} from "../../contexts/ToggleColumn/ToggleColumn";
import {
    DeleteOutlined,
    LockOutlined,
    SettingOutlined,
    UnlockOutlined, UserSwitchOutlined,
    WarningOutlined
} from "@ant-design/icons/lib";
import {deleteConfirm, confirm, alert} from "../../lib/Modal/Modal";
import ClientMessage from "../../lib/Message/ClientMessage";
import Icon, {SecurityScanOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {DrawerDispatch, useItemDrawer} from "../../contexts/drawer/DrawerContext";
import {arr, getDisplayName} from "../../lib/helpers/renderhelper";
import {ManagementV1User} from "../../../gen/models/managementV1User";
import ResetPasswordIcon from "../../images/reset-password-icon.svg";
import UserDrawer, {createResetAccessKey} from "./UserDrawer/UserDrawer";
import {Tooltip} from "../../components/Tooltip/Tooltip";
import {openUserResetPasswordPopup} from "./UserInvitePopup";
import {creationTimestampSorter, numberSorter, stringSorter, timeSorter} from "../../lib/helpers/sorthelper";
import constants from "../../constants/constants";
import {UserInfo} from "../Auth/CompleteProfile/CompleteProfile";
import ShowYamlPopup from "../../components/ShowYamlPopup/ShowYamlPopup";
import UserHeader from "./UserHeader/UserHeader";
import {StorageV1AccountClusterStatus} from "../../../gen/models/storageV1AccountClusterStatus";
import FixedText from "../../components/FixedText/FixedText";
import TextArea from "../../components/TextArea/TextArea";
import {Link, useHistory} from "react-router-dom";
import * as H from "history";
import {Return} from "../../lib/result";
import {displayName} from "../../lib/helper";
import Description from "../../components/Description/Description";
import Query from "../../components/Query/Query";

async function deleteUser(userName: string) {
    const result = await client.management(Resources.ManagementV1User).Delete(userName as string);
    if (result.err) {
        return result;
    }
    
    return Return.Ok();
}

async function disableUser(user: ManagementV1User, refetch: () => Promise<void>, disabled: boolean) {
    const message = ClientMessage.Loading();

    // get user
    const userResult = await client.management(Resources.ManagementV1User).Get(user.metadata?.name!)
    if (userResult.err) {
        message.ErrorManagement(userResult);
        return;
    }

    user = userResult.val;
    if (user.spec) {
        user.spec.disabled = disabled;
    }

    const userUpdate = await client.management(Resources.ManagementV1User).Update(user.metadata?.name!, user);
    if (userUpdate.err) {
        message.ErrorManagement(userUpdate);
        return;
    }

    message.DoneManagement();
    refetch();
}

function lockUser(user: ManagementV1User, refetch: () => Promise<void>) {
    confirm({
        title: "Disable User " + user.metadata?.name,
        content: `Are you sure you want to disable user ${user.metadata?.name}? This will prevent the user from logging in and immediately invalidate all sessions. Existing user resources will not be affected`,
        onOkAsync: async () => await disableUser(user, refetch, true),
        okText: "Disable",
        width: 900, 
    })
}

function unlockUser(user: ManagementV1User, refetch: () => Promise<void>) {
    confirm({
        title: "Enable User " + user.metadata?.name,
        content: `Are you sure you want to enable user ${user.metadata?.name}? This will allow the user to login again and access loft`,
        onOkAsync: async () => await disableUser(user, refetch, false),
        okText: "Enable",
        width: 900,
    })
}

export function renderClusterAccountStatus(status?: {
    'clusters'?: Array<StorageV1AccountClusterStatus>;
}) {
    if (!status || !status.clusters || !status.clusters.length) {
        return undefined;
    }
    
    for (let i = 0; i < status.clusters.length; i++) {
        const clusterStatus = status.clusters[i];
        if (clusterStatus) {
            for (let j = 0; j < arr(clusterStatus.accountsClusterTemplateStatus).length; j++) {
                const templateStatus = clusterStatus.accountsClusterTemplateStatus![j];
                if (templateStatus.phase === "FailedAccount" || templateStatus.phase === "FailedObjects") {
                    return <span className={styles["cluster-error"]} onClick={() => alert({
                        title: `Error applying Template ${templateStatus.name} in Cluster ${clusterStatus.cluster} (${templateStatus.reason})`,
                        content: <TextArea value={templateStatus.message} readOnly={true} />,
                    })}><WarningOutlined />Error</span>
                }
            }
        }
        
        if (clusterStatus.phase === "Failed") {
            return <span className={styles["cluster-error"]} onClick={() => alert({
                    title: `Error syncing Account in Cluster ${clusterStatus.cluster} (${clusterStatus.reason})`,
                    content: <TextArea value={clusterStatus.message} readOnly={true} />,
            })}><WarningOutlined />Error</span>
        }
    }

    return undefined;
}

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch, history: H.History<H.LocationState>) {
    const editUser = (user: ManagementV1User) => {
        drawerDispatcher({
            title: "Edit User: " + user.metadata?.name!,
            content: <UserDrawer mode={"update"} user={user} refetch={refetch} />
        })
    };

    return [
        {
            title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name (ID)']}/>,
            sorter: (a: ManagementV1User, b: ManagementV1User) => stringSorter(displayName(a), displayName(b)),
            render: (user: ManagementV1User) => {
                const link = `/users/details/${user.metadata?.name}`
                return <ToggleColumnContent id={"displayname"} columns={[
                    () => {
                        return <Link to={link}>
                            <FixedText maxWidth={200} className={styles["owner"]} text={getDisplayName(user.spec?.displayName, user.spec?.username, user.metadata?.name)} />
                        </Link>;
                    },
                    () => {
                        return <Link to={link}>
                            <FixedText maxWidth={200} className={styles["owner"]} text={user.metadata?.name} />
                        </Link>;
                    },
                ]}/>
            }
        },
        {
            title: 'Status',
            render: (user: ManagementV1User) => {
                if (user.metadata?.deletionTimestamp) {
                    return <span className="color-warning">Deleting</span>;
                }
                
                // check if the cluster account templates were successful
                const clusterAccountTemplateStatus = renderClusterAccountStatus(user.status);
                if (clusterAccountTemplateStatus) {
                    return clusterAccountTemplateStatus;
                }

                if (user.spec?.disabled) {
                    return <span className="color-warning">Disabled</span>;
                }

                if (!user.metadata?.annotations?.[constants.LoftUserLastActivityAnnotation]) {
                    return <FixedText className="color-warning" text="Invite pending" />;
                }

                return "Active";
            }
        },
        {
            title: "Description",
            render: (user: ManagementV1User) => {
                return <Description.Column>
                    {user.spec?.description || ""}
                </Description.Column>;
            }
        },
        {
            title: 'Username',
            sorter: (a: ManagementV1User, b: ManagementV1User) => stringSorter(a.spec?.username, b.spec?.username),
            render: (user: ManagementV1User) => {
                return <FixedText text={user.spec?.username} />;
            }
        },
        {
            title: 'Email Address',
            sorter: (a: ManagementV1User, b: ManagementV1User) => stringSorter(a.spec?.email, b.spec?.email),
            render: (user: ManagementV1User) => {
                return <FixedText text={user.spec?.email} />;
            }
        },
        {
            title: 'Auth Method',
            render: (user: ManagementV1User) => {
                if (user.metadata?.annotations?.[constants.LoftCreatedBySingleSignOnAnnotation] === "true") {
                    return <FixedText text="Single Sign On" />;
                } else if (user.spec?.passwordRef) {
                    return "Password";
                }

                return "Password";
            }
        },
        {
            title: 'Last Activity',
            width: "180px",
            sorter: (a: ManagementV1User, b: ManagementV1User) => {
                const lastActivityA = Date.parse(a.metadata?.annotations?.[constants.LoftUserLastActivityAnnotation] || "");
                const lastActivityB = Date.parse(b.metadata?.annotations?.[constants.LoftUserLastActivityAnnotation] || "");
                return numberSorter(lastActivityA, lastActivityB);
            },
            render: (user: ManagementV1User) => {
                if (user.metadata?.annotations?.[constants.LoftUserLastActivityAnnotation]) {
                    const lastActivity = Date.parse(user.metadata.annotations[constants.LoftUserLastActivityAnnotation]);
                    if (lastActivity) {
                        return <DynamicTime timestamp={lastActivity} useTooltip={true}/>
                    }
                }
                
                return "Unknown";
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: ManagementV1User, b: ManagementV1User) => creationTimestampSorter(a, b),
            render: (user: ManagementV1User) => {
                return <DynamicTime timestamp={user.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "230px",
            render: (user: ManagementV1User) => {
                return <TableActions className={styles["actions"]}>
                    <Tooltip title="edit">
                        <SettingOutlined className={styles["setting"]} onClick={() => editUser(user)} />
                    </Tooltip>
                    <Tooltip title={user.spec?.disabled ? "enable" : "disable"}>
                        {
                            user.spec?.disabled ? <UnlockOutlined className={styles["setting"]} onClick={() => unlockUser(user, refetch)} /> : <LockOutlined className={styles["setting"]} onClick={() => lockUser(user, refetch)} />
                        }
                    </Tooltip>
                    <Tooltip title="impersonate">
                        <UserSwitchOutlined className={styles["setting"]} onClick={() => {
                            const displayName = getDisplayName(user.spec?.displayName, user.spec?.username, user.metadata?.name);
                            confirm({
                                title: "Impersonate User: " + displayName,
                                content: <React.Fragment>
                                    <div className={"text-bold"}>Are you sure you want to impersonate the user {displayName}? This will reload the page and you can navigate the Loft UI as user {displayName}.</div>
                                    <br />
                                    <div className={"text-bold color-warning"}>You can end impersonation either by logging out or cancel impersonation in the Loft UI header.</div>
                                </React.Fragment>,
                                okText: "Impersonate",
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading();

                                    // check if we are allowed to impersonate
                                    const impersonateResult = await client.management(Resources.V1User).CanI("impersonate");
                                    if (impersonateResult.err) {
                                        message.ErrorManagement(impersonateResult);
                                        return;
                                    } else if (!impersonateResult.val) {
                                        message.ErrorManagement(Return.Failed("user is not allowed to impersonate other users"));
                                        return;
                                    }
                                    
                                    // get subject & groups
                                    const selfResult = await client.management(Resources.ManagementV1Self).Get(user.metadata?.name!);
                                    if (selfResult.err) {
                                        message.ErrorManagement(selfResult);
                                        return;
                                    }
                                    
                                    client.impersonate(displayName, selfResult.val.status?.subject, selfResult.val.status?.groups);
                                    message.DoneManagement();
                                    window.location.reload();
                                }
                            });
                        }} />
                    </Tooltip>
                    <Tooltip title="reset password">
                        <Icon className={styles["reset-password"]} component={ResetPasswordIcon as any} onClick={() =>
                        {
                            const displayName = getDisplayName(user.spec?.displayName, user.spec?.username, user.metadata?.name);
                            confirm({
                                title: "Reset Password: " + displayName,
                                content: <React.Fragment>
                                    <div className={"text-bold"}>Are you sure you want to generate a new password reset link for {displayName}?</div>
                                    <div className={"text-bold color-error"} style={{marginTop: "10px"}}>The user will have to define a new password using a reset password link.</div>
                                </React.Fragment>,
                                okText: "Reset Password",
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading();
                                    const accessKeyResult = await createResetAccessKey(user);
                                    if (accessKeyResult.err) {
                                        message.ErrorManagement(accessKeyResult);
                                        return accessKeyResult;
                                    }
                                    message.DoneManagement();
                                    openUserResetPasswordPopup({
                                        accessKey: accessKeyResult.val
                                    });
                                }
                            });
                        }} />
                    </Tooltip>
                    <ShowYamlPopup className={styles["setting"]} object={user} resource={Resources.ManagementV1User} name={user.metadata?.name!} refetch={refetch} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={styles["delete"]} onClick={() => {
                            deleteConfirm({
                                title: `Delete User: ${user?.metadata?.name}`,
                                content: `Are you sure you want to delete the user ${user?.metadata?.name}? This action CANNOT be reverted!`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading();
                                    const result = await deleteUser(user.metadata?.name!);
                                    message.Result(result);
                                    refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
}

function filter(item: ManagementV1User, value: string) {
    return !!(item.metadata?.name?.includes(value) || (item.spec && ((item.spec.displayName && item.spec.displayName.includes(value)) || (item.spec.username && item.spec.username.includes(value)) || (item.spec.email && item.spec.email.includes(value)) || (item.spec.subject && item.spec.subject.includes(value)))));
}

function Users() {
    const history = useHistory();
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1User).List());
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };
    useEffect(() => {
        const timeout = window.setTimeout(() => refetch(), 4000);
        return () => {
            window.clearTimeout(timeout);
        }
    }, [refetch]);
    
    return <div>
        <Table loading={!data && loading} 
               columns={getTableColumns(refetch, drawerDispatcher, history)} 
               dataSource={data ? arr(data.items).map(user => { return {...user, key: user.metadata!.name!}}) : undefined} 
               error={error} 
               rowSelection={rowSelection} 
               filter={filter} 
               refetch={refetch} 
               header={{
            top: <UserHeader>
                <Description.Table>
                    Users that can access Loft. Click on a user to view the access keys and clusters the user can access. You can also impersonate another user by clicking on the impersonate button.
                </Description.Table>
            </UserHeader>,
            right: <Query query={async () => await client.management(Resources.ManagementV1User).CanI("create")}>
                {
                    result => {
                        if (result.loading || !result.data) {
                            return null;
                        }

                        return <Button type={"primary"} onClick={() => {
                            drawerDispatcher({
                                title: "Add User",
                                content: <UserDrawer mode={"create"} refetch={refetch} />
                            });
                        }}>Add User</Button>
                    }
                }
            </Query>,
            selectedActions: <React.Fragment>
                <Tooltip title={"edit"}>
                    <SettingOutlined className={styles["setting-batch"]} onClick={() =>
                    {
                        const users: ManagementV1User[] = [];
                        for (let i = 0; i < selectedRowKeys.length; i++) {
                            const userName = selectedRowKeys[i];
                            const user = data!.items!.find(user => user.metadata?.name === userName);
                            if (!user) {
                                continue;
                            }

                            users.push(user);
                        }

                        if (users.length === 0) {
                            return;
                        }

                        if (users.length === 1) {
                            const user = users[0];
                            drawerDispatcher({
                                title: "Edit User: " + user?.metadata?.name!,
                                content: <UserDrawer mode={"update"} user={user} refetch={refetch} />
                            })
                        } else {
                            drawerDispatcher({
                                title: "Bulk Edit Selected Users",
                                content: <UserDrawer mode={"batch"} users={users} refetch={refetch} />
                            })
                        }

                        setSelectedRowKeys([]);
                    }} />
                </Tooltip>
                <Tooltip title={"delete"}>
                    <DeleteOutlined className={styles["delete-batch"]} onClick={() =>
                    {
                        deleteConfirm({
                            title: `Delete Users`,
                            content: `Are you sure you want to delete the users ${selectedRowKeys.join(", ")}? This action CANNOT be reverted! This will delete all users accounts, accountquotas and spaces in all clusters as well.`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    const userName = selectedRowKeys[i];
                                    const result = await deleteUser(userName as string);
                                    if (result.err) {
                                        message.Error(result);
                                        return;
                                    }
                                }

                                message?.DoneManagement();
                                await refetch();
                                setSelectedRowKeys([]);
                            }
                        });
                    }} />
                </Tooltip>
            </React.Fragment>
        }} />
    </div>
}

export default Users;